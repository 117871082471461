<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">


      <v-card class="auth-card">

        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="brand-logo d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="230px"
              max-width="230px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

          </router-link>
        </v-card-title>
        <v-row class="auth-row ma-0">

          <v-col
            lg="12"
            class="d-flex align-center auth-bg pb-0"
          >
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="6"
                lg="12"
                class="mx-auto"
              >
                <v-card flat>
                  <v-card-text>
                    <p class="text-2xl font-weight-semibold text--primary mb-2">
                      Passwort vergessen? 🔒
                    </p>
                    <p class="mb-2">
                      Geben Sie Ihre E-Mail Adresse ein und wir senden Ihnen eine Anleitung zum Zurücksetzen Ihres Passwortes.
                    </p>
                  </v-card-text>
                  <v-card-text>

                    <div class="text-center" v-if="loading">
                      <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <v-form
                      v-else
                      ref="form"
                      @submit.prevent="handleFormSubmit"
                    >
                      <v-text-field
                        v-model="email"
                        outlined
                        label="E-Mail"
                        placeholder="john@example.com"
                        :error="(errorMessages.email != undefined)"
                        :error-messages="errorMessages.email"
                        :rules="[validators.required, validators.emailValidator]"
                        hide-details="auto"
                        class="mb-4"
                      ></v-text-field>

                      <v-btn
                        block
                        color="primary"
                        type="submit"
                      >
                        Absenden
                      </v-btn>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center align-center">
                    <router-link
                      :to="{name:'auth-login'}"
                      class="d-flex align-center text-sm"
                    >
                      <v-icon
                        size="24"
                        color="primary"
                      >
                        {{ icons.mdiChevronLeft }}
                      </v-icon>
                      <span>Zurück zum Login</span>
                    </router-link>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>




    </div>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiCheckOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import axios from "@axios";
import { required, emailValidator } from '@core/utils/validation'
import {getCurrentInstance} from "@vue/composition-api/dist/vue-composition-api";

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const errorMessages = ref([])
    const form = ref(null)
    const email = ref('')
    const loading = ref(false)
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return

      loading.value = true;
      axios
        .post('/api/passwort_vergessen/', {
          aktion: 'passwordForgottenFormCheck',
          userData: {
            email: email.value
          }
        })
        .then(response => {
          if(response.data.valid === true){
            vm.$toastr.s(response.data.message.text, response.data.message.title)

          }
          else{

            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })

            errorMessages.value = response.data.errors;
          }
          loading.value = false;
        })
        .catch(error => {
          loading.value = false;
          console.log(error)
        })
    }
    return {
      form,
      handleFormSubmit,
      email,
      errorMessages,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiChevronLeft,
        mdiCheckOutline
      },
      validators: {
        required,
        emailValidator,
      },
      loading
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
